import {
  DownOutlined,
  MenuOutlined,
  MoonFilled,
  MoonOutlined,
  ShareAltOutlined,
  SunOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Dropdown, Image, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { setCurrentTheme, setTheme } from "../redux/theme/themeSlice";
import { themes } from "../global/ThemeProvider";
import { useMediaQuery } from "react-responsive";
import Sidebar from "./Sidebar";

const PageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);

  const isMediumScreen = useMediaQuery({ maxWidth: 1023 });
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [collapsed, setCollapsed] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setCollapsed(false);
  };

  useEffect(() => {
    setCollapsed(isMediumScreen);
  }, [isMediumScreen]);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(isMediumScreen);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMediumScreen]);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    { key: "home", label: "Home", path: "/" },
    { key: "products", label: "Products", path: "/products" },
    { key: "about-us", label: "About Us", path: "/about-us" },
    { key: "contact", label: "Contact", path: "/contact" },
    { key: "whytextaj", label: "Why Textaj", path: "/why-textaj" },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        className="font-semibold"
        key="denim"
        onClick={() => navigate("/products/denim", { replace: true })}
      >
        Denim
      </Menu.Item>
      <Menu.Item
        key="knit"
        onClick={() => navigate("/products/knit", { replace: true })}
      >
        Knit
      </Menu.Item>
      <Menu.Item
        key="sweater"
        onClick={() => navigate("/products/sweater", { replace: true })}
      >
        Sweater
      </Menu.Item>
      <Menu.Item
        key="woven"
        onClick={() => navigate("/products/woven", { replace: true })}
      >
        Woven
      </Menu.Item>
    </Menu>
  );
  const getCurrentSelectedKey = () => {
    const matchedItem = menuItems.find((item) =>
      matchPath(location.pathname, item.path)
    );
    return matchedItem ? matchedItem.key : "home"; // Default to "home" if no match found
  };

  const changeTheme = (sTheme) => {
    const mode = sTheme === "default_light" ? "light" : "dark";
    dispatch(setTheme(mode));
    dispatch(setCurrentTheme(themes[sTheme]));
  };

  console.log(isMediumScreen, collapsed);

  return (
    <div className="w-full flex items-center justify-between">
      <div className="w-full flex items-center justify-start  overflow-hidden dark:bg-slate-900">
        {theme === "dark" ? (
          <Image
            src="/assets/logo.png"
            width={150}
            height={80}
            alt="logo"
            preview={false}
          />
        ) : (
          <Image
            src="/assets/logo.png"
            width={150}
            height={80}
            alt="logo"
            preview={false}
          />
        )}
      </div>

      {!isMediumScreen && (
        <div className="w-full flex items-center justify-center min-w-[500px]">
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[getCurrentSelectedKey()]}
            className="bg-slate-50 dark:bg-slate-900 w-full  text-center h-20 "
            disabledOverflow
          >
            <Menu.Item
              key="home"
              className="font-[500] hover:!bg-[#fcb99c] dark:hover:!text-black active:bg-[#fcb99c]  dark:text-white h-full"
              onClick={() => navigate("/", { replace: true })}
            >
              Home
            </Menu.Item>
            <Menu.Item
              key="about-us"
              className="font-[500] hover:!bg-[#fcb99c] dark:hover:!text-black active:bg-[#fcb99c] dark:text-white h-full"
              onClick={() => navigate("/about-us", { replace: true })}
            >
              About
            </Menu.Item>
            <Dropdown
              overlay={menu}
              trigger={["hover"]}
              className="font-[500] hover:!bg-[#fcb99c] dark:hover:!text-black active:bg-[#fcb99c] dark:text-white h-full"
            >
              <Menu.Item
                key="products"
                className="font-[500] hover:!bg-[#fcb99c] dark:hover:!text-black active:bg-[#fcb99c]"
                onClick={() => navigate("/products", { replace: true })}
              >
                Products <DownOutlined style={{ fontSize: 10 }} />
              </Menu.Item>
            </Dropdown>
            <Menu.Item
              key="whytextaj"
              className="font-[500] hover:!bg-[#fcb99c] dark:hover:!text-black active:bg-[#fcb99c] dark:text-white h-full"
              onClick={() => navigate("/why-textaj", { replace: true })}
            >
              Why Textaj
            </Menu.Item>
            <Menu.Item
              key="contact"
              className="font-[500] hover:!bg-[#fcb99c] dark:hover:!text-black active:bg-[#fcb99c] dark:text-white h-full"
              onClick={() => navigate("/contact", { replace: true })}
            >
              Contact
            </Menu.Item>
          </Menu>
        </div>
      )}

      <div className="w-full flex items-center justify-end gap-4">
        {theme === "dark" ? (
          <Button
            onClick={() => changeTheme("default_light")}
            type="primary"
            className="flex items-center justify-center"
          >
            {theme === "dark" ? <SunOutlined /> : <MoonOutlined />}
          </Button>
        ) : (
          <Button
            onClick={() => changeTheme("default_dark")}
            type="primary"
            className="flex items-center justify-center"
          >
            {theme === "dark" ? <SunOutlined /> : <MoonOutlined />}
          </Button>
        )}

        {isMediumScreen && (
          <Button
            type="primary"
            className="flex items-center justify-center"
            onClick={() => {
              showDrawer();
            }}
          >
            <MenuOutlined />
          </Button>
        )}

        {isMediumScreen && open && (
          <Drawer
            style={{ width: 250, height: "calc(100vh)", zIndex: 99999999 }}
            bodyStyle={{ padding: 0, overflowX: "hidden", overflowY: "auto" }}
            headerStyle={{ padding: 5 }}
            placement={placement}
            width={250}
            onClose={onClose}
            open={open}
            maskClosable={false}
          >
            <Sidebar />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default PageHeader;

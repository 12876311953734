import React from "react";
import Marquee from "react-fast-marquee";

const TrustedPartners = () => {
  return (
    <div className="py-10 bg-white dark:bg-slate-950">
      <div className="container mx-auto">
        <h2 className="font-bold text-4xl text-slate-900 dark:text-white text-center">
          Trusted Partners
        </h2>
        <div className="my-10">
          <Marquee autoFill speed={30} style={{ height: 100 }}>
            <span className="mx-10 font-semibold text-4xl">
              Jeans Manufacturing Company Limited(Denim, Woven)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Carlos Leather Fashions Limited(Denim, Woven)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              APlus Industries( Sweater, Woven)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Valmont Sweaters Limited(Sweater)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Space Sweaters limited (Sweater)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              SS fashion(Woven)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Pakija Knitwear(Knit)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Razia Apparels(Knit)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Rizvi Fashion(knit)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Parashmoni Sweaters limited(Sweaters)
            </span>
            <span className="mx-10 font-semibold text-4xl">
              Susuka Knit Limited(Knit)
            </span>
          </Marquee>
        </div>
        <div className="w-full text-center">
          <h2 className="font-bold text-3xl text-slate-900 dark:text-white text-center">
            Work with us
          </h2>
          <p className="text-base font-semibold">
            Join our team and lets create magic together
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrustedPartners;

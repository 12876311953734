import React from "react";
import HomeAbout from "../../components/home/HomeAbout";
import { Image } from "antd";
import Team from "../../components/home/Team";

const AboutUs = () => {
  return (
    <div className="w-full bg-white dark:bg-slate-900 pt-10">
      <h2 className="font-bold text-5xl text-slate-700 dark:text-white text-center">
        Textaj Sourcing International
      </h2>
      <h2 className="font-bold text-sm text-slate-900 dark:text-white text-center uppercase mt-2">
        Sustainable Apparel Solution
      </h2>

      <div className=" w-full md:w-2/3 container mx-auto">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-10 mt-16">
          <div className="flex flex-col items-start gap-5">
            {/* <h2 className="font-bold text-5xl text-slate-700 dark:text-white text-left">
              Textaj Sourcing International
            </h2>
            <h2 className="font-bold text-sm text-slate-900 dark:text-white text-center uppercase -mt-2">
              Sustainable Apparel Solution
            </h2> */}
            <div className="mt-5 flex flex-col gap-5">
              <p className="text-base">
                <span className="font-bold text-xl">Overview:</span>
                &nbsp;Founded in 2020, Textaj Sourcing International is a
                premier apparel sourcing and manufacturing agent based in
                Bangladesh. Specializing in sustainable fashion, the company has
                rapidly established itself as a reliable partner for clients
                around the globe, including Denmark, France, Italy, the USA, the
                UK, Australia, and various Scandinavian countries.
              </p>
              <p className="text-base">
                <span className="font-bold text-xl">
                  Commitment to Sustainability:
                </span>
                &nbsp; Textaj Sourcing International prides itself on offering
                eco-friendly products that do not compromise on style. The
                company&#39;s product range includes apparel made from BCI
                (Better Cotton Initiative) cotton, organic materials, and
                recycled fibers. This commitment to sustainability ensures that
                each piece of clothing is not only fashionable but also
                environmentally responsible.
              </p>
              <p className="text-base">
                <span className="font-bold text-xl">Mission: </span>&nbsp;Textaj
                Sourcing International’s mission is to provide sustainable and
                eco-friendly products that meet the fashion needs of their
                clients while protecting the environment. This dual focus on
                style and sustainability sets the company apart in the
                competitive of apparel manufacturing.
              </p>
            </div>
          </div>
          <div className="w-full flex items-start justify-center">
            <Image
              preview={false}
              src="/assets/images/common/16.jpg"
              alt="company image"
              height="80%"
            />
          </div>
        </div>
      </div>
      <Team />
    </div>
  );
};

export default AboutUs;

import React from "react";
import { useParams } from "react-router-dom";
import WithSubCategory from "../../../components/category-wise-product/WithSubCategory";
import WithoutSubCategory from "../../../components/category-wise-product/WithoutSubCategory";

const CategoryWise = () => {
  const params = useParams();
  const { category } = params;
  return (
    <div className="w-full p-3">
      <div className="container mx-auto py-10">
        <h2 className="text-4xl text-black  dark:text-white text-center capitalize mt-5">
          {category}
          {category === "sweater" ||
          category === "denim" ||
          category === "woven" ? (
            <WithSubCategory category={category} />
          ) : (
            <WithoutSubCategory category={category} />
          )}
        </h2>
      </div>
    </div>
  );
};

export default CategoryWise;

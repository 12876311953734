import React from "react";
import CountUp from "react-countup";

const AllCounter = () => {
  return (
    <div className="bg-slate-300 dark:bg-slate-950 py-10">
      <div className="container mx-auto w-full flex items-center justify-between">
        <div className="w-full flex flex-col items-center justify-center">
          <CountUp
            style={{ fontWeight: 600, fontSize: 25 }}
            start={0}
            end={12}
            suffix="+"
            duration={3}
          />
          <span className="text-2xl font-semibold">Clients</span>
        </div>

        <div className="w-full flex flex-col items-center justify-center">
          <CountUp
            style={{ fontWeight: 600, fontSize: 25 }}
            start={0}
            end={1.5}
            decimals={1}
            suffix="K"
            duration={3}
          />
          <span className="text-2xl font-semibold">Orders Fullfilled</span>
        </div>

        <div className="w-full flex flex-col items-center justify-center">
          <CountUp
            style={{ fontWeight: 600, fontSize: 25 }}
            start={0}
            end={9}
            suffix="+"
            duration={3}
          />
          <span className="text-2xl font-semibold">Countries</span>
        </div>
      </div>
    </div>
  );
};

export default AllCounter;

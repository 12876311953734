import React, { useMemo, useState } from "react";
import { featuredProductList } from "../../data/data";
import { Button, Carousel, Image } from "antd";
import { useMediaQuery } from "react-responsive";
import ProductCard from "../ProductCard";
import { useNavigate } from "react-router-dom";

const categoryList = [
  { id: 1, category: "all", value: "all" },
  { id: 2, category: "denim", value: "denim" },
  { id: 3, category: "knit", value: "knit" },
  { id: 4, category: "sweater", value: "sweater" },
  { id: 5, category: "woven", value: "woven" },
];
const settings = {
  dots: false,
  //   infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  adaptiveHeight: true,
  className: "image-slider",
};

const FeaturedProducts = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("all");
  const isMediumScreen = useMediaQuery({ maxWidth: 1023 });
  const isSmallScreen = useMediaQuery({ maxWidth: 834 });

  // const filteredProducts = useMemo(() => {
  //   if (filter === "all") return featuredProductList;
  //   return featuredProductList.filter((item) => item.category === filter);
  // }, [filter]);

  const shuffledProducts = useMemo(() => {
    if (filter === "all") {
      return shuffleArray(featuredProductList);
    }
    return shuffleArray(
      featuredProductList.filter((item) => item.category === filter)
    );
  }, [filter]);
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  return (
    <div className="bg-slate-50 py-16 featured-product dark:bg-slate-900">
      <div className="w-[90%] mx-auto">
        <h2 className="font-bold text-4xl text-slate-900 dark:text-white text-center">
          Featured Products
        </h2>
        <div className="w-full flex items-center justify-center">
          <div className="flex items-center gap-2 my-10">
            {categoryList.map((item, index) => (
              <Button
                key={index + 1}
                type={item.category === filter ? "primary" : "default"}
                onClick={() => setFilter(item.category)}
                className="uppercase text-sm font-semibold"
              >
                {item.category}
              </Button>
            ))}
          </div>
        </div>
        <Carousel
          arrows
          infinite={true}
          dots={false}
          autoplaySpeed={4000}
          slidesPerRow={isSmallScreen ? 2 : isMediumScreen ? 3 : 5}
          slidesToScroll={1}
        >
          {shuffledProducts.map((product) => (
            <div
              key={product.id}
              className="w-full flex items-center gap-4 overflow-hidden"
            >
              <ProductCard
                key={product._id}
                name={product.name}
                img={product.img}
                category={product.category}
              />
              {/* <Image
                src={product.img}
                alt={product.name}
                className="w-[100%] shadow-md"
                height={300}
                width="95%"
              /> */}
            </div>
          ))}
        </Carousel>
        <div className="flex items-center justify-center my-10">
          <Button
            type="primary"
            onClick={() => navigate("/products", { replace: true })}
          >
            VIEW ALL PRODUCT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;

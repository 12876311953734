import { EnvironmentFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";

const ContactUs = () => {
  return (
    <div className="bg-white dark:bg-slate-900 w-full pt-10">
      <div className="container mx-auto py-5">
        <h2 className="font-bold text-4xl text-slate-900 dark:text-white text-center mb-10">
          Contact Us
        </h2>

        <div className="bg-slate-50 dark:bg-slate-900 w-full py-16">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="flex flex-col gap-10">
              <h2 className="font-bold text-4xl text-slate-700 dark:text-white text-left">
                Get In Touch
              </h2>
              <div className="flex flex-col gap-5">
                <Input placeholder="Full Name" />
                <Input placeholder="Email Address" />
                <Input placeholder="Phone Number" />
                <Input.TextArea rows={4} placeholder="Your Message" />
                <Button type="primary">Send E-Mail</Button>
              </div>
            </div>
            <div>
              <h2 className="font-bold text-4xl text-slate-700 dark:text-white text-left">
                Contact Info
              </h2>
              <div className="mt-10">
                <div className="flex flex-col gap-3 text-xl">
                  <div className="flex items-center gap-3">
                    <PhoneFilled /> 01551805996
                  </div>
                  <div className="flex items-center gap-3">
                    <MailFilled /> merchandiser@textaj.com
                  </div>
                  <div className="flex items-center gap-3">
                    <EnvironmentFilled /> 13-14, Zoo Road, Mollik Tower. Mirpur
                    1 , Dhaka-1216
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-10 bg-white dark:bg-slate-950">
        <div className="py-10 container mx-auto ">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462.2001004515305!2d90.35253986944281!3d23.802898970194725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c1798f626e13%3A0x70051b647f947e51!2sDaraz%20Collection%20point%20Mirpur%20Zoo%20Road%20Station!5e0!3m2!1sen!2sbd!4v1716885864870!5m2!1sen!2sbd"
            height="450"
            style={{ border: 0, width: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import {
  EnvironmentFilled,
  FacebookFilled,
  FacebookOutlined,
  LinkedinFilled,
  LinkedinOutlined,
  MailFilled,
  PhoneFilled,
} from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      {/* sub footer */}
      <div className="w-full bg-slate-700 dark:bg-slate-800 text-white py-10">
        <div className="md:w-2/3 mx-auto flex items-center justify-between gap-5">
          {/* office time */}
          <div className="flex flex-col gap-3">
            <h2 className="font-semibold text-xl">Our Company</h2>
            <ul>
              <li
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/about-us", { replace: true })}
              >
                About us
              </li>
              <li
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/products", { replace: true })}
              >
                Products
              </li>
              <li
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/why-textaj", { replace: true })}
              >
                Why Textaj
              </li>
            </ul>
          </div>
          {/* contact */}
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-3">
              <h2 className="font-semibold text-xl">Textaj</h2>
              <div className="flex items-center gap-3">
                <PhoneFilled /> 01551805996
              </div>
              <div className="flex items-center gap-3">
                <MailFilled /> merchandiser@textaj.com
              </div>
              <div className="flex items-center gap-3">
                <EnvironmentFilled /> 13-14, Zoo Road, Mollik Tower. Mirpur 1 ,
                Dhaka-1216
              </div>
            </div>
            <div className="flex items-center gap-5">
              <a
                href="https://www.facebook.com/profile.php?id=61560083846022"
                target="_blank"
                className="size-10 border-2 border-white rounded-full flex items-center justify-center hover:cursor-pointer hover:bg-blue-700 hover:text-white"
              >
                <FacebookOutlined style={{ fontSize: 20 }} />
              </a>

              <a
                href="https://www.linkedin.com/company/textaj-sourcing-international/"
                target="_blank"
                className="size-10 border-2 border-white rounded-full flex items-center justify-center hover:cursor-pointer hover:bg-sky-950 hover:text-white"
              >
                <LinkedinOutlined style={{ fontSize: 20 }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-slate-900 py-5 text-white font-semibold text-center">
        &copy; {new Date().getFullYear()} | Textaj Sourcing International
      </div>
    </div>
  );
};

export default PageFooter;

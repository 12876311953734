import { Menu, Pagination } from "antd";
import React, { useMemo, useState } from "react";
import { productList } from "../../data/data";
import ProductCard from "../../components/ProductCard";
import { useMediaQuery } from "react-responsive";

const items = [
  {
    key: "suball",
    label: "All",
    category: "all",
  },
  {
    key: "sub1",
    label: "Denim",
    category: "denim",
    children: [
      {
        key: "g21",
        label: "Ladies",
        category: "ladies-denim",
      },
      {
        key: "g33",
        label: "Men",
        category: "men-denim",
      },
    ],
  },
  {
    key: "sub2",
    label: "Knit",
    category: "knit",
  },
  {
    key: "sub3",
    label: "Sweater",
    category: "sweater",
    children: [
      {
        key: "g1",
        label: "Kids",
        category: "kid",
      },
      {
        key: "g2",
        label: "Ladies",
        category: "ladies",
      },
      {
        key: "g3",
        label: "Men",
        category: "men",
      },
    ],
  },
  {
    key: "sub4",
    label: "Woven",
    category: "woven",
    children: [
      {
        key: "g211",
        label: "Ladies",
        category: "ladies-woven",
      },
      {
        key: "g333",
        label: "Men",
        category: "men-woven",
      },
    ],
  },
];

const findMenuItem = (items, key) => {
  for (const item of items) {
    if (item.key === key) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const child = findMenuItem(item.children, key);
      if (child) {
        return child;
      }
    }
  }
  return null;
};
const Products = () => {
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProductCategoryWise, setTotalProductCategoryWise] = useState(0);
  const isMediumScreen = useMediaQuery({ maxWidth: 767 });

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setRowsPerPage(pageSize);
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  //   const filteredProducts = useMemo(() => {
  //     if (filter === "all") return productList;
  //     return productList.filter(
  //       (item) => item.category === filter || item.subCategory === filter
  //     );
  //   }, [filter]);

  const filteredProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    if (filter === "all") {
      setTotalProductCategoryWise(productList.length);
      return productList.slice(startIndex, endIndex);
    }

    let filteredList = productList.filter(
      (item) => item.category === filter || item.subCategory === filter
    );
    setTotalProductCategoryWise(filteredList.length);
    return filteredList.slice(startIndex, endIndex);
  }, [currentPage, rowsPerPage, filter]);

  const onClick = (info) => {
    const { key } = info;
    const menuItem = findMenuItem(items, key);
    if (menuItem && menuItem.category) {
      setFilter(menuItem.category);
    }
  };

  return (
    <div className="w-full product-list py-5">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-[250px_minmax(250px,1fr)] gap-5 my-5  ">
        <div
          className={`${
            isMediumScreen ? "w-full" : "w-[250px]"
          } place-self-center md:place-self-start`}
        >
          <Menu
            className="py-10"
            onClick={onClick}
            style={{
              width: isMediumScreen ? "100%" : 250,
              padding: "0 5px",
              fontSize: "13px !important",
              fontWeight: 400,
            }}
            defaultSelectedKeys={["suball"]}
            defaultOpenKeys={[]}
            mode={isMediumScreen ? "horizontal" : "inline"}
            disabledOverflow
            items={items}
          />
        </div>
        <div className="flex items-start justify-center">
          <div className=" px-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 ">
            {filteredProducts.map((product) => (
              <ProductCard
                key={product._id}
                name={product.name}
                img={product.img}
                category={product.category}
                fromProduct={true}
              />
            ))}
          </div>
        </div>
        <div>&nbsp;</div>
        <div className="w-full flex items-center justify-end">
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={onChange}
            defaultCurrent={currentPage}
            total={totalProductCategoryWise}
          />
        </div>
      </div>
    </div>
  );
};

export default Products;

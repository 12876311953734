import React, { useMemo, useState } from "react";
import ProductCard from "../ProductCard";
import { Button, Pagination, Result } from "antd";
import { productList } from "../../data/data";

const WithoutSubCategory = ({ category }) => {
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProductCategoryWise, setTotalProductCategoryWise] = useState(0);

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setRowsPerPage(pageSize);
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  const filteredProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    if (filter === "all") {
      setTotalProductCategoryWise(
        productList.filter((x) => x.category === category).length
      );
      return productList
        .filter((x) => x.category === category)
        .slice(startIndex, endIndex);
    }

    let filteredList = productList.filter(
      (item) => item.category === filter || item.subCategory === filter
    );
    setTotalProductCategoryWise(filteredList.length);
    return filteredList.slice(startIndex, endIndex);
  }, [currentPage, rowsPerPage, filter, category]);

  if (filteredProducts.length === 0) {
    return <Result title="No data found " />;
  }

  return (
    <div className="w-full py-10">
      <div className="px-5 md:px-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {filteredProducts.map((product) => (
          <ProductCard
            key={product._id}
            name={product.name}
            img={product.img}
            category={product.category}
            fromProduct={true}
          />
        ))}
      </div>
      <div className="w-full flex items-center justify-end my-5">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          defaultCurrent={currentPage}
          total={totalProductCategoryWise}
        />
      </div>
    </div>
  );
};

export default WithoutSubCategory;

import {
  DownOutlined,
  MenuOutlined,
  MoonFilled,
  MoonOutlined,
  ShareAltOutlined,
  SunOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Dropdown, Image, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { setCurrentTheme, setTheme } from "../redux/theme/themeSlice";
import { themes } from "../global/ThemeProvider";
import { useMediaQuery } from "react-responsive";

const menuItems = [
  { key: "home", label: "Home", path: "/" },
  { key: "products", label: "Products", path: "/products" },
  { key: "about-us", label: "About Us", path: "/about-us" },
  { key: "contact", label: "Contact", path: "/contact" },
  { key: "whytextaj", label: "Why Textaj", path: "/why-textaj" },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const menu = (
    <Menu>
      <Menu.Item
        className="font-semibold"
        key="denim"
        onClick={() => navigate("/products/denim", { replace: true })}
      >
        Denim
      </Menu.Item>
      <Menu.Item
        key="knit"
        onClick={() => navigate("/products/knit", { replace: true })}
      >
        Knit
      </Menu.Item>
      <Menu.Item
        key="sweater"
        onClick={() => navigate("/products/sweater", { replace: true })}
      >
        Sweater
      </Menu.Item>
      <Menu.Item
        key="woven"
        onClick={() => navigate("/products/woven", { replace: true })}
      >
        Woven
      </Menu.Item>
    </Menu>
  );
  const getCurrentSelectedKey = () => {
    const matchedItem = menuItems.find((item) =>
      matchPath(location.pathname, item.path)
    );
    return matchedItem ? matchedItem.key : "home"; // Default to "home" if no match found
  };
  return (
    <div className="sidebar">
      <Menu
        mode="inline"
        defaultSelectedKeys={[getCurrentSelectedKey()]}
        className="bg-slate-50 dark:bg-slate-900 w-full  text-center h-full "
        disabledOverflow
      >
        <Menu.Item
          key="home"
          className="font-[500] hover:!bg-blue-100 active:bg-blue-100  dark:text-white text-left"
          onClick={() => navigate("/", { replace: true })}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="about-us"
          className="font-[500] hover:!bg-blue-100 active:bg-blue-100 dark:text-white text-left"
          onClick={() => navigate("/about-us", { replace: true })}
        >
          About
        </Menu.Item>
        <Dropdown
          overlay={menu}
          trigger={["hover"]}
          className="font-[500] hover:!bg-blue-100 active:bg-blue-100 dark:text-white  text-left pl-10"
        >
          <Menu.Item
            key="products"
            className="font-[500] hover:!bg-blue-100 active:bg-blue-100"
            onClick={() => navigate("/products", { replace: true })}
          >
            Products <DownOutlined style={{ fontSize: 10 }} />
          </Menu.Item>
        </Dropdown>

        <Menu.Item
          key="whytextaj"
          className="font-[500] hover:!bg-blue-100 active:bg-blue-100 dark:text-white text-left"
          onClick={() => navigate("/why-textaj", { replace: true })}
        >
          Why Textaj
        </Menu.Item>
        <Menu.Item
          key="contact"
          className="font-[500] hover:!bg-blue-100 active:bg-blue-100 dark:text-white text-left"
          onClick={() => navigate("/contact", { replace: true })}
        >
          Contact
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;

import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Card, Carousel } from "antd";

export const reviews = [
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1605462863863-10d9e47e15ee?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "John Doe",
    text: "I was extremely pleased with the quality of the product. It exceeded my expectations and provided great value for the price.",
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Jane Smith",
    text: "The customer service was excellent. They were responsive and helpful throughout the entire process, making it a smooth experience for me.",
  },
  {
    id: 3,
    image:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Alex Johnson",
    text: "The attention to detail in their work is impressive. Every aspect of the project was handled with precision and care. I highly recommend their services.",
  },
  {
    id: 4,
    image:
      "https://plus.unsplash.com/premium_photo-1671823917954-dc943c1bd9df?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Emily Davis",
    text: "The team demonstrated a deep understanding of my requirements. They were able to capture the essence of my vision and deliver a product that exceeded my expectations.",
  },
  {
    id: 5,
    image:
      "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "David Miller",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
  },
];
const Testimonial = () => {
  return (
    <div className="testimonial">
      <section className="w-full py-10 bg-slate-50 dark:bg-slate-900 component">
        <h2 className="font-bold text-4xl text-slate-900 dark:text-white text-center">
          Testimonial
        </h2>
        <div className="container mx-auto my-10">
          <Carousel
            arrows
            infinite={true}
            dots={false}
            autoplaySpeed={4000}
            slidesPerRow={1}
            slidesToScroll={1}
          >
            {reviews.map((review) => (
              <div key={review.id} className=" gap-10  py-10">
                <blockquote className="callout quote EN !flex flex-col gap-5 items-center justify-center w-[90%]">
                  <img
                    src={review.image}
                    alt="client"
                    className="size-28 rounded-full "
                  />
                  <p className="w-1/2 text-center italic text-base  dark:text-white">
                    It’s good to meet you, Dr. Banner. Your work on
                    anti-electron collisions is unparalleled. And I’m a huge fan
                    of the way you lose control and turn into an enormous green
                    rage monster.
                  </p>
                  <cite className=" dark:text-white"> - {review.name}</cite>
                </blockquote>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;

import { ConfigProvider } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const themes = {
  default_light: {
    colorBgBase: "#ffffff",
    colorPrimary: "#F56530",
    colorInfo: "#F56530",
    colorLink: "#ea580c",
    colorTextBase: "#000000",
    colorSuccess: "#16a34a",
    colorWarning: "#ca8a04",
    colorError: "#dc2626",
  },
  default_dark: {
    colorBgBase: "#0f172a",
    colorPrimary: "#F56530",
    colorInfo: "#F56530",
    colorLink: "#ea580c",
    colorTextBase: "#ffffff",
    colorSuccess: "#16a34a",
    colorWarning: "#ca8a04",
    colorError: "#dc2626",
    colorBgLayout: "#020617",
  },
};
export function hexToRGBA(hex, opacity) {
  // Remove the leading # if present
  hex = hex.replace("#", "");

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Ensure opacity is within the range of 0 to 1
  opacity = Math.min(1, Math.max(0, opacity));

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const ThemeProvider = ({ children }) => {
  const currentTheme = useSelector((state) => state.theme.currentTheme);
  const theme = useSelector((state) => state.theme.theme);
  useEffect(() => {
    if (
      theme === "dark" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: currentTheme.colorBgBase,
          colorPrimary: currentTheme.colorPrimary,
          colorInfo: currentTheme.colorInfo,
          colorLink: currentTheme.colorLink,
          colorTextBase: currentTheme.colorTextBase,
          colorSuccess: currentTheme.colorSuccess,
          colorWarning: currentTheme.colorWarning,
          colorError: currentTheme.colorError,
          fontFamily: `"Inter", sans-serif`,
          Button: {
            primaryShadow: "none !important",
          },
          Menu: {
            // colorItemBgHover: "red",
            colorItemBgSelected: hexToRGBA(currentTheme.colorPrimary, 0.1),
            colorItemTextSelected: currentTheme.colorPrimary,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;

import { Card, Image } from "antd";
import React from "react";

const Team = () => {
  return (
    <div className="bg-slate-50 py-20 w-full px-5 dark:bg-black">
      <div className="mb-10">
        <h2 className="text-3xl font-bold text-center">Our Team</h2>
      </div>
      <div className=" w-full md:w-[800px] mx-auto flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <Card
            bodyStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 20,
              flexDirection: "column",
            }}
            className="aspect-square flex items-center justify-center text-center hover:shadow-lg  max-w-sm hover:bg-[#F56530]  hover:cursor-pointer group"
          >
            <Image
              src="/assets/images/common/Merchandising.jpg"
              className="aspect-square"
              alt="Merchandising"
              preview={false}
            />
            <h3 className="text-2xl font-semibold text-[#F56530] group-hover:text-white">
              Merchandising
            </h3>
            <p className="font-semibold text-sm">
              A bunch of young energetic textile based professionals
              Merchandisers.{" "}
            </p>
          </Card>
          <Card
            bodyStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 20,
              flexDirection: "column",
            }}
            className="aspect-square flex items-center justify-center text-center hover:shadow-lg  max-w-sm hover:bg-[#F56530]  hover:cursor-pointer group"
          >
            <Image
              src="/assets/images/common/R&D.jpg"
              className="aspect-square"
              alt="R&D"
              preview={false}
            />
            <h3 className="text-2xl font-semibold text-[#F56530] group-hover:text-white group-hover:cursor-move ">
              R&D
            </h3>
            <p className="font-semibold text-sm">
              We have the professionals fashion designers on research and
              Development of up to date trends and fashions.{" "}
            </p>
          </Card>
          <Card
            bodyStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 20,
              flexDirection: "column",
            }}
            className="aspect-square flex items-center justify-center text-center hover:shadow-lg  max-w-sm hover:bg-[#F56530]  hover:cursor-pointer group"
          >
            <Image
              src="/assets/images/common/Quality.jpg"
              className="aspect-square"
              alt="Quality"
              preview={false}
            />
            <h3 className="text-2xl font-semibold text-[#F56530] group-hover:text-white group-hover:cursor-move ">
              Quality Team
            </h3>
            <p className="font-semibold text-sm">
              Long experienced and ability of technical analysis who can give
              instant solutions on any quality issues.{" "}
            </p>
          </Card>
          <Card
            bodyStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 20,
              flexDirection: "column",
            }}
            className="aspect-square flex items-center justify-center text-center hover:shadow-lg  max-w-sm hover:bg-[#F56530]  hover:cursor-pointer group"
          >
            <Image
              src="/assets/images/common/Commercial.jpg"
              className="aspect-square"
              alt="Commercial"
              preview={false}
            />
            <h3 className="text-2xl font-semibold text-[#F56530] group-hover:text-white group-hover:cursor-move ">
              Commercial team
            </h3>
            <p className="font-semibold text-sm">
              Engaged with Banking, transportation and shipment process.{" "}
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Team;

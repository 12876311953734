import { Image } from "antd";
import React from "react";

const WhyTextaj = () => {
  return (
    <div className="w-full pb-10">
      <div className="w-2/3 mx-auto">
        <div
          className="bg-cover bg-[center_center] bg-no-repeat p-28 relative h-56"
          style={{ backgroundImage: `url(/assets/images/common/16.jpg)` }}
        ></div>
      </div>
      <h2 className="text-center text-slate-700 dark:text-white text-5xl font-bold mt-10">
        <span className="text-3xl text-black dark:text-white">Why Choose</span>{" "}
        <br />
        Textaj Sourcing International?
      </h2>
      <div className=" container mx-auto mt-5">
        <div className="py-5 flex flex-col gap-5 w-2/3 mx-auto">
          <div className="mt-5 flex flex-col gap-5">
            <p className="text-base">
              <span className="font-bold text-xl">Expert Team:</span>&nbsp;The
              company boasts a team of expert merchandisers with backgrounds in
              textile engineering. Their deep industry knowledge and technical
              expertise ensure that clients receive the highest quality
              products.
            </p>
            <p className="text-base">
              <span className="font-bold text-xl">Innovative Design:</span>
              &nbsp; With a professional fashion design team, Textaj Sourcing
              International stays ahead of market trends. The designers are
              dedicated to developing new styles and trends, making it easy for
              clients to access the latest in fashion.
            </p>
            <p className="text-base">
              <span className="font-bold text-xl">Focus on R&amp;D: </span>
              &nbsp;Research and Development is a core focus at Textaj Sourcing
              International. The company continually invests in R&amp;D to
              create innovative designs and materials that keep them at the
              forefront of the industry. This commitment ensures that clients
              always have access to the newest trends and sustainable solutions.
            </p>
            <p className="text-base">
              <span className="font-bold text-xl">Global Reach: </span>
              &nbsp;Textaj Sourcing International serves a diverse client base
              across multiple continents, demonstrating their ability to meet
              varied fashion and sustainability needs. Their global presence
              underscores their reliability and adaptability in the
              international market.
            </p>
            <p className="text-base">
              <span className="font-bold text-xl">
                One Stop Sample Supports:{" "}
              </span>
              &nbsp;Textaj Sourcing International have own samples unit to
              support its clients various types of sample requirements including
              Salesman Samples. We know the value of samples and on emergency
              supports we can manage samples within 2-3 working days.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyTextaj;

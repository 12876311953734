import "./App.css";
import ThemeProvider from "./global/ThemeProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalLayout from "./components/GlobalLayout";
import ErrorPage from "./pages/error/ErrorPage";
import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import CategoryWise from "./pages/products/category-wise/CategoryWise";
import AboutUs from "./pages/about-us/AboutUs";
import ContactUs from "./pages/contact/ContactUs";
import WhyTextaj from "./pages/why-textaj/WhyTextaj";

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GlobalLayout />}>
            <Route index element={<Home />} />

            <Route path="*" element={<ErrorPage />} />
            <Route path="products" element={<Products />} />
            <Route path="products/:category" element={<CategoryWise />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="why-textaj" element={<WhyTextaj />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import React, { useMemo, useState } from "react";
import ProductCard from "../ProductCard";
import { Button, Pagination } from "antd";
import { productList } from "../../data/data";

const WithSubCategory = ({ category }) => {
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProductCategoryWise, setTotalProductCategoryWise] = useState(0);

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setRowsPerPage(pageSize);
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  const filteredProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    if (filter === "all") {
      setTotalProductCategoryWise(
        productList.filter((x) => x.category === category).length
      );
      return productList
        .filter((x) => x.category === category)
        .slice(startIndex, endIndex);
    }

    let filteredList = productList.filter(
      (item) => item.category === category && item.subCategory === filter
    );
    setTotalProductCategoryWise(filteredList.length);
    return filteredList.slice(startIndex, endIndex);
  }, [currentPage, rowsPerPage, filter, category]);

  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-center">
        <div className="flex items-center gap-2 my-10">
          {category === "denim" || category === "woven"
            ? [
                { label: "all", value: "all" },
                {
                  label: "ladies",
                  value: category === "denim" ? "ladies-denim" : "ladies-woven",
                },
                {
                  label: "men",
                  value: category === "denim" ? "men-denim" : "men-woven",
                },
              ].map((item, index) => (
                <Button
                  key={index + 1}
                  type={item.value === filter ? "primary" : "default"}
                  onClick={() => setFilter(item.value)}
                  className="uppercase text-sm font-semibold"
                >
                  {item.label}
                </Button>
              ))
            : ["all", "kid", "ladies", "men"].map((item, index) => (
                <Button
                  key={index + 1}
                  type={item === filter ? "primary" : "default"}
                  onClick={() => setFilter(item)}
                  className="uppercase text-sm font-semibold"
                >
                  {item}
                </Button>
              ))}
        </div>
      </div>
      <div className="px-5 md:px-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {filteredProducts.map((product) => (
          <ProductCard
            key={product._id}
            name={product.name}
            img={product.img}
            category={product.category}
            fromProduct={true}
          />
        ))}
      </div>
      <div className="w-full flex items-center justify-end">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          defaultCurrent={currentPage}
          total={totalProductCategoryWise}
        />
      </div>
    </div>
  );
};

export default WithSubCategory;

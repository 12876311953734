import { Card, Image } from "antd";
import React from "react";
const { Meta } = Card;

const ProductCard = ({ name, img, category, fromProduct }) => {
  return (
    <>
      {/* <Card
        className={`${fromProduct ? "" : "m-2"} max-w-[300px]`}
        hoverable
        cover={
          <Image
            alt={name}
            height={250}
            // width="100%"
            src={img}
            className=" border-b p-1"
          />
        }
      >
        <Meta title={name || ""} className="!text-xs" />
      </Card> */}

      <div
        className={`group hover:shadow-md transition relative border bg-white rounded-lg ${
          !fromProduct ? "mx-2" : ""
        }`}
      >
        <div className="aspect-square w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75">
          <Image
            alt={name}
            src={img}
            className="h-full w-full object-contain object-center"
          />
        </div>
        {/* <div className="mt-4 flex justify-between p-3">
          <div>
            <h3 className="text-sm text-gray-700 group-hover:text-blue-600">
              {name}
            </h3>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProductCard;

import { Layout } from "antd";
import React from "react";
import PageHeader from "./PageHeader";
import { Outlet } from "react-router-dom";
import PageFooter from "./PageFooter";
const { Header, Content } = Layout;

const GlobalLayout = () => {
  return (
    <Layout>
      <Header className="fixed top-0 left-0 right-0 shadow-md bg-slate-50 dark:!bg-slate-900 text-black z-[999] h-20">
        <PageHeader />
      </Header>
      <Content className="pt-24">
        <Outlet />
      </Content>
      <div className="bg-white">
        <PageFooter />
      </div>
    </Layout>
  );
};

export default GlobalLayout;

export const featuredProductList = [
  {
    id: 1,
    category: "denim",
    name: "Denim Product 1",
    img: "/assets/images/Denim/1.jpg",
  },
  {
    id: 2,
    category: "denim",
    name: "Denim Product 2",
    img: "/assets/images/Denim/2.jpg",
  },
  {
    id: 3,
    category: "denim",
    name: "Denim Product 3",
    img: "/assets/images/Denim/3.jpg",
  },
  {
    id: 4,
    category: "denim",
    name: "Denim Product 4",
    img: "/assets/images/Denim/4.jpg",
  },
  {
    id: 6,
    category: "denim",
    name: "Denim Product 6",
    img: "/assets/images/Denim/6.jpg",
  },
  {
    id: 7,
    category: "denim",
    name: "Denim Product 7",
    img: "/assets/images/Denim/7.jpg",
  },
  {
    id: 8,
    category: "denim",
    name: "Denim Product 8",
    img: "/assets/images/Denim/8.jpg",
  },

  {
    id: 9,
    category: "knit",
    name: "Knit Product 9",
    img: "/assets/images/Knit/1.jpg",
  },
  {
    id: 10,
    category: "knit",
    name: "Knit Product 10",
    img: "/assets/images/Knit/2.jpg",
  },
  {
    id: 11,
    category: "knit",
    name: "Knit Product 11",
    img: "/assets/images/Knit/3.jpg",
  },
  {
    id: 12,
    category: "knit",
    name: "Knit Product 12",
    img: "/assets/images/Knit/4.jpg",
  },
  {
    id: 13,
    category: "knit",
    name: "Knit Product 13",
    img: "/assets/images/Knit/5.jpg",
  },
  {
    id: 14,
    category: "knit",
    name: "Knit Product 14",
    img: "/assets/images/Knit/6.jpg",
  },
  {
    id: 15,
    category: "knit",
    name: "Knit Product 15",
    img: "/assets/images/Knit/7.jpg",
  },
  {
    id: 16,
    category: "knit",
    name: "Knit Product 16",
    img: "/assets/images/Knit/8.jpg",
  },

  {
    id: 17,
    category: "sweater",
    name: "Sweater Product 17",
    img: "/assets/images/Sweater/Kidz-Sweater/1.jpg",
  },
  {
    id: 18,
    category: "sweater",
    name: "Sweater Product 18",
    img: "/assets/images/Sweater/Kidz-Sweater/2.jpg",
  },
  {
    id: 19,
    category: "sweater",
    name: "Sweater Product 19",
    img: "/assets/images/Sweater/Kidz-Sweater/3.jpg",
  },
  {
    id: 20,
    category: "sweater",
    name: "Sweater Product 20",
    img: "/assets/images/Sweater/Ladies-Sweater/1.webp",
  },
  {
    id: 21,
    category: "sweater",
    name: "Sweater Product 21",
    img: "/assets/images/Sweater/Ladies-Sweater/2.jpg",
  },
  {
    id: 22,
    category: "sweater",
    name: "Sweater Product 22",
    img: "/assets/images/Sweater/Ladies-Sweater/3.jpg",
  },
  {
    id: 23,
    category: "sweater",
    name: "Sweater Product 23",
    img: "/assets/images/Sweater/Mens-Sweater/1.jpg",
  },
  {
    id: 24,
    category: "sweater",
    name: "Sweater Product 24",
    img: "/assets/images/Sweater/Mens-Sweater/2.webp",
  },

  {
    id: 25,
    category: "woven",
    name: "Knit Product 25",
    img: "/assets/images/Knit/1.jpg",
  },
  {
    id: 26,
    category: "woven",
    name: "Woven Product 26",
    img: "/assets/images/Woven/Mens/2.jpg",
  },
  {
    id: 27,
    category: "woven",
    name: "Woven Product 27",
    img: "/assets/images/Woven/Mens/3.jpg",
  },
  {
    id: 28,
    category: "woven",
    name: "Woven Product 28",
    img: "/assets/images/Woven/Mens/4.jpg",
  },
  {
    id: 29,
    category: "woven",
    name: "Woven Product 29",
    img: "/assets/images/Woven/Mens/5.jpg",
  },
  {
    id: 30,
    category: "woven",
    name: "Woven Product 14",
    img: "/assets/images/Woven/Mens/6.jpg",
  },
  {
    id: 31,
    category: "woven",
    name: "Woven Product 31",
    img: "/assets/images/Woven/Mens/7.jpg",
  },
  {
    id: 32,
    category: "woven",
    name: "Woven Product 32",
    img: "/assets/images/Woven/Mens/8.jpg",
  },
];

export const productList = [
  // denim
  {
    id: 1,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 1",
    img: "/assets/images/Denim/Ladies/1.jpg",
  },
  {
    id: 2,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 2",
    img: "/assets/images/Denim/Ladies/2.jpg",
  },
  {
    id: 3,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 3",
    img: "/assets/images/Denim/Ladies/3.jpg",
  },
  {
    id: 4,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 4",
    img: "/assets/images/Denim/Ladies/4.jpg",
  },
  {
    id: 5,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 5",
    img: "/assets/images/Denim/Ladies/5.jpg",
  },
  {
    id: 6,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 6",
    img: "/assets/images/Denim/Ladies/6.jpg",
  },
  {
    id: 7,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 7",
    img: "/assets/images/Denim/Ladies/7.jpg",
  },
  {
    id: 8,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 8",
    img: "/assets/images/Denim/Ladies/8.jpg",
  },
  {
    id: 9,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 9",
    img: "/assets/images/Denim/Ladies/9.jpg",
  },
  {
    id: 10,
    category: "denim",
    subCategory: "ladies-denim",
    name: "Denim Product 10",
    img: "/assets/images/Denim/Ladies/10.jpg",
  },

  {
    id: 11,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 1",
    img: "/assets/images/Denim/Mens/1.jpg",
  },
  {
    id: 12,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 2",
    img: "/assets/images/Denim/Mens/2.jpg",
  },
  {
    id: 13,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 3",
    img: "/assets/images/Denim/Mens/3.jpg",
  },
  {
    id: 14,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 4",
    img: "/assets/images/Denim/Mens/4.jpg",
  },
  {
    id: 15,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 5",
    img: "/assets/images/Denim/Mens/5.jpg",
  },
  {
    id: 16,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 6",
    img: "/assets/images/Denim/Mens/6.jpg",
  },
  {
    id: 17,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 7",
    img: "/assets/images/Denim/Mens/7.jpg",
  },
  {
    id: 18,
    category: "denim",
    subCategory: "men-denim",
    name: "Denim Product 8",
    img: "/assets/images/Denim/Mens/8.jpg",
  },

  // knit
  {
    id: 19,
    category: "knit",
    name: "Knit Product 1",
    img: "/assets/images/Knit/1.jpg",
  },
  {
    id: 20,
    category: "knit",
    name: "Knit Product 2",
    img: "/assets/images/Knit/2.jpg",
  },
  {
    id: 21,
    category: "knit",
    name: "Knit Product 3",
    img: "/assets/images/Knit/3.jpg",
  },
  {
    id: 22,
    category: "knit",
    name: "Knit Product 4",
    img: "/assets/images/Knit/4.jpg",
  },
  {
    id: 23,
    category: "knit",
    name: "Knit Product 5",
    img: "/assets/images/Knit/5.jpg",
  },
  {
    id: 24,
    category: "knit",
    name: "Knit Product 6",
    img: "/assets/images/Knit/6.jpg",
  },
  {
    id: 25,
    category: "knit",
    name: "Knit Product 7",
    img: "/assets/images/Knit/7.jpg",
  },
  {
    id: 26,
    category: "knit",
    name: "Knit Product 8",
    img: "/assets/images/Knit/8.jpg",
  },
  {
    id: 27,
    category: "knit",
    name: "Knit Product 9",
    img: "/assets/images/Knit/9.jpg",
  },
  {
    id: 29,
    category: "knit",
    name: "Knit Product 10",
    img: "/assets/images/Knit/10.jpg",
  },
  {
    id: 30,
    category: "knit",
    name: "Knit Product 11",
    img: "/assets/images/Knit/11.jpg",
  },
  {
    id: 31,
    category: "knit",
    name: "Knit Product 12",
    img: "/assets/images/Knit/12.jpg",
  },
  {
    id: 32,
    category: "knit",
    name: "Knit Product 13",
    img: "/assets/images/Knit/13.jpg",
  },
  {
    id: 33,
    category: "knit",
    name: "Knit Product 14",
    img: "/assets/images/Knit/14.jpg",
  },
  {
    id: 34,
    category: "knit",
    name: "Knit Product 15",
    img: "/assets/images/Knit/15.jpg",
  },
  {
    id: 35,
    category: "knit",
    name: "Knit Product 16",
    img: "/assets/images/Knit/16.jpg",
  },
  {
    id: 36,
    category: "knit",
    name: "Knit Product 17",
    img: "/assets/images/Knit/17.jpg",
  },

  // sweater
  {
    id: 37,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 1",
    img: "/assets/images/Sweater/Kidz-Sweater/1.jpg",
  },
  {
    id: 38,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 2",
    img: "/assets/images/Sweater/Kidz-Sweater/2.jpg",
  },
  {
    id: 39,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 3",
    img: "/assets/images/Sweater/Kidz-Sweater/3.jpg",
  },
  {
    id: 40,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 4",
    img: "/assets/images/Sweater/Kidz-Sweater/4.jpg",
  },
  {
    id: 41,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 5",
    img: "/assets/images/Sweater/Kidz-Sweater/5.jpg",
  },
  {
    id: 42,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 6",
    img: "/assets/images/Sweater/Kidz-Sweater/6.jpg",
  },
  {
    id: 43,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 7",
    img: "/assets/images/Sweater/Kidz-Sweater/7.jpg",
  },
  {
    id: 44,
    subCategory: "kid",
    category: "sweater",
    name: "Kids Sweater Product 8",
    img: "/assets/images/Sweater/Kidz-Sweater/8.jpg",
  },
  {
    id: 45,
    category: "sweater",
    subCategory: "kid",
    name: "Kids Sweater Product 9",
    img: "/assets/images/Sweater/Kidz-Sweater/9.jpg",
  },

  //ladies
  {
    id: 46,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 1",
    img: "/assets/images/Sweater/Ladies-Sweater/1.webp",
  },
  {
    id: 47,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 2",
    img: "/assets/images/Sweater/Ladies-Sweater/2.jpg",
  },
  {
    id: 48,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 3",
    img: "/assets/images/Sweater/Ladies-Sweater/3.jpg",
  },
  {
    id: 49,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 4",
    img: "/assets/images/Sweater/Ladies-Sweater/4.jpg",
  },
  {
    id: 50,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 5",
    img: "/assets/images/Sweater/Ladies-Sweater/5.jpg",
  },
  {
    id: 51,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 6",
    img: "/assets/images/Sweater/Ladies-Sweater/6.jpg",
  },
  {
    id: 52,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 7",
    img: "/assets/images/Sweater/Ladies-Sweater/7.jpg",
  },
  {
    id: 53,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 8",
    img: "/assets/images/Sweater/Ladies-Sweater/8.jpg",
  },
  {
    id: 55,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 9",
    img: "/assets/images/Sweater/Ladies-Sweater/9.jpg",
  },
  {
    id: 56,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 10",
    img: "/assets/images/Sweater/Ladies-Sweater/10.jpg",
  },
  {
    id: 57,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 11",
    img: "/assets/images/Sweater/Ladies-Sweater/11.jpg",
  },
  {
    id: 58,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 12",
    img: "/assets/images/Sweater/Ladies-Sweater/12.jpg",
  },
  {
    id: 59,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 13",
    img: "/assets/images/Sweater/Ladies-Sweater/13.jpg",
  },
  {
    id: 60,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 14",
    img: "/assets/images/Sweater/Ladies-Sweater/14.jpg",
  },
  {
    id: 61,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 15",
    img: "/assets/images/Sweater/Ladies-Sweater/15.webp",
  },
  {
    id: 62,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 16",
    img: "/assets/images/Sweater/Ladies-Sweater/16.jpg",
  },
  {
    id: 63,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 17",
    img: "/assets/images/Sweater/Ladies-Sweater/17.jpg",
  },
  {
    id: 64,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 18",
    img: "/assets/images/Sweater/Ladies-Sweater/18.jpg",
  },
  {
    id: 65,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 19",
    img: "/assets/images/Sweater/Ladies-Sweater/19.jpg",
  },
  {
    id: 66,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 20",
    img: "/assets/images/Sweater/Ladies-Sweater/20.jpg",
  },
  {
    id: 67,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 21",
    img: "/assets/images/Sweater/Ladies-Sweater/21.jpg",
  },
  {
    id: 68,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 22",
    img: "/assets/images/Sweater/Ladies-Sweater/22.jpg",
  },
  {
    id: 69,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 23",
    img: "/assets/images/Sweater/Ladies-Sweater/23.jpg",
  },
  {
    id: 70,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 24",
    img: "/assets/images/Sweater/Ladies-Sweater/24.png",
  },
  {
    id: 71,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 25",
    img: "/assets/images/Sweater/Ladies-Sweater/25.jpg",
  },
  {
    id: 72,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 26",
    img: "/assets/images/Sweater/Ladies-Sweater/26.jpg",
  },
  {
    id: 73,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 27",
    img: "/assets/images/Sweater/Ladies-Sweater/27.jpg",
  },
  {
    id: 74,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 28",
    img: "/assets/images/Sweater/Ladies-Sweater/28.jpg",
  },
  {
    id: 75,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 29",
    img: "/assets/images/Sweater/Ladies-Sweater/29.jpg",
  },
  {
    id: 76,
    category: "sweater",
    subCategory: "ladies",
    name: "Ladies Sweater Product 30",
    img: "/assets/images/Sweater/Ladies-Sweater/30.jpg",
  },

  //men

  {
    id: 77,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 1",
    img: "/assets/images/Sweater/Mens-Sweater/1.jpg",
  },
  {
    id: 78,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 2",
    img: "/assets/images/Sweater/Mens-Sweater/2.webp",
  },
  {
    id: 79,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 3",
    img: "/assets/images/Sweater/Mens-Sweater/3.jpg",
  },
  {
    id: 80,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 4",
    img: "/assets/images/Sweater/Mens-Sweater/4.jpg",
  },
  {
    id: 81,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 5",
    img: "/assets/images/Sweater/Mens-Sweater/5.jpg",
  },
  {
    id: 82,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 6",
    img: "/assets/images/Sweater/Mens-Sweater/6.jpg",
  },
  {
    id: 83,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 7",
    img: "/assets/images/Sweater/Mens-Sweater/7.jpg",
  },
  {
    id: 84,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 8",
    img: "/assets/images/Sweater/Mens-Sweater/8.jpg",
  },
  {
    id: 85,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 9",
    img: "/assets/images/Sweater/Mens-Sweater/9.jpeg",
  },

  {
    id: 86,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 10",
    img: "/assets/images/Sweater/Mens-Sweater/10.jpg",
  },
  {
    id: 87,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 11",
    img: "/assets/images/Sweater/Mens-Sweater/11.jpg",
  },
  {
    id: 88,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 12",
    img: "/assets/images/Sweater/Mens-Sweater/12.jpeg",
  },
  {
    id: 89,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 13",
    img: "/assets/images/Sweater/Mens-Sweater/13.jpeg",
  },
  {
    id: 90,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 14",
    img: "/assets/images/Sweater/Mens-Sweater/14.jpeg",
  },
  {
    id: 91,
    category: "sweater",
    subCategory: "men",
    name: "Men Sweater Product 15",
    img: "/assets/images/Sweater/Mens-Sweater/15.jpg",
  },

  // woven
  {
    id: 93,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/1.jpg",
  },
  {
    id: 94,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/2.jpg",
  },
  {
    id: 95,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/3.jpg",
  },
  {
    id: 96,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/4.jpg",
  },
  {
    id: 97,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/5.jpg",
  },
  {
    id: 98,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/6.jpg",
  },
  {
    id: 99,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/7.jpg",
  },
  {
    id: 100,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/8.jpg",
  },
  {
    id: 101,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/9.jpg",
  },
  {
    id: 102,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/10.jpg",
  },
  {
    id: 103,
    category: "woven",
    subCategory: "men-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Mens/11.jpg",
  },

  {
    id: 104,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/1.jpg",
  },
  {
    id: 105,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/2.jpg",
  },
  {
    id: 106,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/3.jpg",
  },
  {
    id: 107,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/4.jpg",
  },
  {
    id: 108,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/5.jpg",
  },
  {
    id: 109,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/6.jpg",
  },
  {
    id: 110,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/7.jpg",
  },
  {
    id: 111,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/8.jpg",
  },
  {
    id: 112,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/9.jpg",
  },
  {
    id: 113,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/10.jpg",
  },
  {
    id: 114,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/11.jpg",
  },
  {
    id: 115,
    category: "woven",
    subCategory: "ladies-woven",
    name: "Woven Product 1",
    img: "/assets/images/Woven/Ladies/12.jpg",
  },
];

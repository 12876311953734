import React from "react";
import HeroCarousel from "../../components/home/HeroCarousel";
import HomeAbout from "../../components/home/HomeAbout";
import FeaturedProducts from "../../components/home/FeaturedProducts";
import TrustedPartners from "../../components/home/TrustedPartners";
import AllCounter from "../../components/home/AllCounter";
import Testimonial from "../../components/home/Testimonial";
import Team from "../../components/home/Team";

const Home = () => {
  return (
    <div>
      <HeroCarousel />
      <HomeAbout />
      <FeaturedProducts />
      <AllCounter />
      {/* <Testimonial /> */}
      <Team />
      <TrustedPartners />
    </div>
  );
};

export default Home;
